export const darkTheme = {
    colors: {
        background: "#1a1a2e",
        text: "#ffffff",
        primary: "#ff4081",
        secondary: "#202040",
    },
};

export default darkTheme;

