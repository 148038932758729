import styled from 'styled-components';
import { FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';

const SidebarContainer = styled.div`
  position: fixed;
  right: 1rem;
  top: 80%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary}; // Use theme color
  padding: 1rem;
  border-radius: 8px 0 0 8px;
  z-index: 1000;
`;

const IconLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  margin: 0.5rem 0;
  font-size: 1.5rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary}; // Use theme color for hover
  }
`;

const Separator = styled.div`
  width: 2px;
  height: 50px;
  background: ${({ theme }) => theme.colors.text};
  margin: 1rem 0;
`;

const SocialSidebar = () => {
  return (
    <SidebarContainer>
      <IconLink href="mailto:youremail@example.com" target="_blank" rel="noopener noreferrer">
        <FaEnvelope />
      </IconLink>
      <IconLink href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
        <FaLinkedin />
      </IconLink>
      <IconLink href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer">
        <FaGithub />
      </IconLink>
      <Separator />
    </SidebarContainer>
  );
};

export default SocialSidebar;

