import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import darkTheme from "./styles/theme";
import lightTheme from "./styles/lightTheme";
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import About from "./components/About";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import SocialSidebar from "./components/SocialSidebar";
import Wrapper from "./components/Wrapper"; 

const App: React.FC = () => {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <GlobalStyles />
      <Navbar toggleTheme={toggleTheme} isDarkMode={theme === "dark"} />
      <SocialSidebar />
      <Wrapper>
        <Intro />
        <About />
        <Projects />
        <Skills />
        <Contact />
      </Wrapper>
    </ThemeProvider>
  );
};

export default App;
