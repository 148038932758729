export const lightTheme = {
    colors: {
      background: "#ffffff",
      text: "#000000",
      primary: "#ff4081", // Pink
      secondary: "#B0C4DE", // Soft Gray-Blue
    },
  };
  
  export default lightTheme;
  