import React from "react";
import styled from "styled-components";
import { FaExternalLinkAlt, FaGithub } from "react-icons/fa";

const ProjectsContainer = styled.div`
  padding: 2rem;
`;

const ProjectSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  text-align: left;
  background: none; /* No background */
  margin: 2rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const ProjectImage = styled.img`
  width: 45%; /* Slightly smaller image */
  border-radius: 8px;
  border: 5px solid ${({ theme }) => theme.colors.primary}; /* Pink outline */
  box-shadow: 0 0 15px 5px ${({ theme }) => theme.colors.primary}; /* Glow effect */
  margin-right: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 1rem; /* Adjust margin to place image below */
  }
`;

const ProjectDetails = styled.div`
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ProjectTitle = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
`;

const ProjectTechnologies = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0.5rem 0;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 1rem; /* Add space between text and image */
`;

const ProjectLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const ProjectLink = styled.a`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-size: 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ProjectLinkIcon = styled.div`
  margin-right: 0.5rem;
`;

interface ProjectProps {
  image: string;
  title: string;
  technologies: string;
  description: string;
  liveLink: string;
  codeLink: string;
}

const Project: React.FC<ProjectProps> = ({
  image,
  title,
  technologies,
  description,
  liveLink,
  codeLink,
}) => {
  return (
    <ProjectSection id="projects">
      <ProjectDetails>
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectTechnologies>Made with: {technologies}</ProjectTechnologies>
        <ProjectDescription>{description}</ProjectDescription>
        <ProjectLinks>
          <ProjectLink
            href={liveLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProjectLinkIcon>
              <FaExternalLinkAlt />
            </ProjectLinkIcon>{" "}
            Live
          </ProjectLink>
          <ProjectLink
            href={codeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProjectLinkIcon>
              <FaGithub />
            </ProjectLinkIcon>{" "}
            Code
          </ProjectLink>
        </ProjectLinks>
      </ProjectDetails>
      <ProjectImage src={image} alt={`${title} screenshot`} />
    </ProjectSection>
  );
};

const Projects: React.FC = () => {
  return (
    <ProjectsContainer>
      <Project
        image="https://via.placeholder.com/400x200"
        title="Testing Project"
        technologies="Go, React"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut"
        liveLink=""
        codeLink=""
      />
      <Project
        image="https://via.placeholder.com/400x200"
        title="Testing Project"
        technologies="Go, React"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut"
        liveLink=""
        codeLink=""
      />
      <Project
        image="https://via.placeholder.com/400x200"
        title="Testing Project"
        technologies="Go, React"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut"
        liveLink=""
        codeLink=""
      />
      <Project
        image="https://via.placeholder.com/400x200"
        title="Testing Project"
        technologies="Go, React"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut"
        liveLink=""
        codeLink=""
      />
    </ProjectsContainer>
  );
};

export default Projects;
