import styled from "styled-components";
import { FaEnvelope, FaGithub, FaLinkedin, FaFileAlt } from "react-icons/fa";

const ContactSection = styled.section`
  padding: 2rem;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const ContactLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
`;

const ContactText = styled.span`
  font-size: 1rem;
`;

const Footer = styled.footer`
  margin-top: 2rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text};
`;

const Contact = () => {
  return (
    <ContactSection id="contact">
      <ContactContainer>
        <ContactLink href="" target="_blank" rel="noopener noreferrer">
          <FaEnvelope />
          <ContactText>Email</ContactText>
        </ContactLink>
        <ContactLink href="" target="_blank" rel="noopener noreferrer">
          <FaGithub />
          <ContactText>GitHub</ContactText>
        </ContactLink>
        <ContactLink href="" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
          <ContactText>LinkedIn</ContactText>
        </ContactLink>
        <ContactLink href="" target="_blank" rel="noopener noreferrer">
          <FaFileAlt />
          <ContactText>Resume</ContactText>
        </ContactLink>
      </ContactContainer>
      <Footer>&copy; 2023-present Mark Allison. All Rights Reserved</Footer>
    </ContactSection>
  );
};

export default Contact;
