import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh; /* Ensures the section takes up full height of the viewport */
  text-align: center;
  background: ${({ theme }) => theme.colors.background};
`;

const AboutTitle = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutColumn = styled(motion.div)`
  max-width: 30%;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 2rem;
  }
`;

const AboutSubtitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const AboutParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const titleVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const columnVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const About = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the section is visible
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <AboutSection id="about" ref={ref}>
      <AboutTitle initial="hidden" animate={controls} variants={titleVariants}>
        About Me
      </AboutTitle>
      <AboutContainer as={motion.div} initial="hidden" animate={controls} variants={containerVariants}>
        <AboutColumn variants={columnVariants}>
          <AboutSubtitle>Past</AboutSubtitle>
          <AboutParagraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut
          </AboutParagraph>
        </AboutColumn>
        <AboutColumn variants={columnVariants}>
          <AboutSubtitle>Present</AboutSubtitle>
          <AboutParagraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut
          </AboutParagraph>
        </AboutColumn>
        <AboutColumn variants={columnVariants}>
          <AboutSubtitle>Future</AboutSubtitle>
          <AboutParagraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus felis. Nullam sit amet lacus euismod, consequat nunc id, ultricies tortor. Cras nec risus vitae risus ultrices tincidunt. Etiam non dignissim mi, quis maximus lectus. Phasellus ac ante non odio vehicula consectetur. Nullam at risus ut purus tincidunt tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed nec quam vel ante cursus tincidunt. Aliquam erat volut
          </AboutParagraph>
        </AboutColumn>
      </AboutContainer>
    </AboutSection>
  );
};

export default About;
